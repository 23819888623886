<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
      <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
        <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-form-model-item label='商品名称' prop='name'>
            <a-input disabled v-model='form.name' />
            <!-- <a-select show-search v-model='form.production'
                      :default-active-first-option='false'
                      :filter-option='false'
                      placeholder="请选择"
                      @search='handleSearch'
                      @change="handleChange"
                      >
              <a-select-option v-for='item in prodList' :key='item.id'>
                {{ item.name }}
              </a-select-option>
            </a-select> -->
          </a-form-model-item>
          <!-- <a-form-model-item label='存放仓库' prop='depository'>
            <a-select show-search v-model='form.depository'
                      :default-active-first-option='false'
                      :filter-option='false'
                      placeholder="请选择"
                      @search='handleSearch1'>
              <a-select-option v-for='item in houseList' :key='item.id'>
                {{ item.depositoryName }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label='入库数' prop='storageCount'>
            <a-input-number v-model='form.storageCount' />
          </a-form-model-item>
          <a-form-model-item label='规格' prop='specs'>
            <a-input disabled v-model='form.specs' />
          </a-form-model-item>
          <a-form-model-item label="入库时间" prop='storageTim'>
            <a-date-picker valueFormat="YYYY-MM-DD" v-model="form.storageTim" style="width: 100%" placeholder="选择日期"/>
          </a-form-model-item>
          <!-- <a-form-model-item label='生产厂家' prop='factory'>
            <a-input v-model='form.factory' />
          </a-form-model-item>
          <a-form-model-item label="生产时间" prop='productionTime'>
            <a-date-picker valueFormat="YYYY-MM-DD" v-model="form.productionTime" style="width: 100%" placeholder="选择日期"/>
          </a-form-model-item>
          <a-form-model-item label='本次生产数量' prop='productionQuantity'>
            <a-input-number v-model='form.productionQuantity' />
          </a-form-model-item>
          <a-form-model-item label='加工费用' prop='cos'>
            <a-input v-model='form.cos' />
          </a-form-model-item>
          <a-form-model-item label='成品运输费用' prop='transportationCost'>
            <a-input v-model='form.transportationCost' />
          </a-form-model-item> -->
          <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
            <a-button @click='handleReset'>
              重置
            </a-button>
            <a-divider type='vertical' />
            <a-button @click='handleCancel'>
              取消
            </a-button>
            <a-divider type='vertical' />
            <a-button type='primary' @click='handleSubmit'>
              提交
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-card>
      <a-card title="出入库记录" :body-style="{padding: '24px 32px'}" :bordered='false' style="margin-top: 20px;">
        <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :scroll='{ x: 1500}' :data='loadData1'>
          <span slot='name' slot-scope='text'>
            <template>
              <Ellipsis tooltip :length='20'>{{ text }}</Ellipsis>
            </template>
          </span>
          <span slot='beforeCount' slot-scope='text, record'>
            <template>
              {{parseInt(text) +parseInt(record.storageCount)}}
            </template>
          </span>
        </s-table>
      </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {
  putInsideProdStorehouse,
  queryInsideProd,
  queryInsideStorehouse,
  queryInsideStorehouseProdList 
} from '@/api/inside'
import moment from 'moment'

const columns1 = [
  {
    width: 150,
    title: '商品名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 100,
    title: '类型',
    dataIndex: 'typeName',
    scopedSlots: { customRender: 'typeName' }
  },
  {
    width: 100,
    title: '数量',
    dataIndex: 'storageCount'
  },
  {
    width: 100,
    title: '剩余数量',
    dataIndex: 'beforeCount',
    scopedSlots: { customRender: 'beforeCount' }
  },
  {
    width: 150,
    title: '时间',
    dataIndex: 'createTime'
  },
  {
    width: 150,
    title: '操作人',
    dataIndex: 'opUsername',
  }
]

export default {
  name: 'InsideStorehouseEdit',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 8 }, sm: { span: 17 } },
      prodList: [],
      houseList: [],
      form: {
        storageTim: moment().format("YYYY-MM-DD"),
        productionTime: moment().format("YYYY-MM-DD"),
        contractStatus: 0
      },
      rules: {
        production: [
          { required: true, message: '请选择商品', trigger: 'change' }
        ],
        depository: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        storageCount: [
          { required: true, message: '请填写入库数', trigger: 'change' }
        ],
        storageCount: [
          { required: true, message: '请填写入库数', trigger: 'change' },
          { type: 'integer', message: '入库数填写整数', trigger: 'change' }
        ],
        specs: [
          { required: true, message: '请填写规格', trigger: 'change' }
        ],
        storageTim: [
          { required: true, message: '请填写入库时间', trigger: 'change' }
        ],
        factory: [
          { required: true, message: '请填写生产厂家', trigger: 'change' }
        ],
        productionTime: [
          { required: true, message: '请填写生产时间', trigger: 'change' }
        ],
        productionQuantity: [
          { required: true, message: '请填写本次生产数量', trigger: 'change' },
          { type: 'integer', message: '本次生产数量填写整数', trigger: 'change' }
        ],
        cos: [
          { required: true, message: '请填写加工费用', trigger: 'change' }
        ],
        transportationCost: [
          { required: true, message: '请填写成品运输费用', trigger: 'change' }
        ],
      },
      // 表头
      columns1,
      // 加载数据方法 必须为 Promise 对象
      queryParam1: {  isDefault: 0 },
      loadData1: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        parameter.production = this.production;
        // console.log(this.form)
        return queryInsideStorehouseProdList(Object.assign(parameter, this.queryParam1))
          .then(datum => {
            return datum
          })
      },
    }
  },
  created() {
    let production = this.$route.query.depositoryProduction;
    let name = this.$route.query.name;
    let specs = this.$route.query.specs;

    this.production = production;
    this.handleInitial(production,name,specs)
    // this.$form.createForm(this)
  },
  activated() {
    let production = this.$route.query.depositoryProduction;
    let name = this.$route.query.name;
    let specs = this.$route.query.specs;

    this.production = production;
    this.handleInitial(production,name,specs)
  },
  methods: {
    handleChange(e) {
      let msg = this.prodList.find(item => item.id == e)
      this.form.name = msg.name;
      this.form.specs = msg.specs;
    },
    handleSearch(keywords) {
      queryInsideProd({ keywords: keywords, pageMode: 0  }).then(result => {
        this.prodList = result.records
      })
    },
    handleSearch1(keywords) {
      queryInsideStorehouse({ keywords: keywords, pageMode: 0, isDefault: 0 }).then(result => {
        this.houseList = result.records
        this.form.depository = result.records[0].id;
      })
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial(production,name,specs) {
      this.form.production = production;
      this.form.name = name;
      this.form.specs = specs;
      this.handleSearch1();
      if(this.$refs.table1){
        this.$refs.table1.refresh(true)
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          let myForm = Object.assign(this.form);
          debugger;
          putInsideProdStorehouse(myForm).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
